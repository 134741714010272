// Dataset Hooks
import { useEffect, useState } from "react"
import { fetchParticipantData, fetchPendingIssues, fetchPhoneData, fetchPhonePlacementData, fetchReceivedSensorFiles, fetchSensorData, fetchSensorHousePlacementData, fetchWearableData, fetchWearablePlacementData } from "../store/actions/datasetActions"
import { resetAllDatasets } from "../store/actions/generalStoreActions"
import * as libCon from "../community-hats-js-library/Constants"
import * as dm_fun from "../utils/dataManipulationFunctions"
import { RefStoreValue } from "./GeneralStoreHooks"


export const refreshAllDatasets = () => {

    // Resets All Datasets
    resetAllDatasets()

    fetchParticipantData()
    fetchSensorData()
    fetchSensorHousePlacementData()
    fetchPhoneData()
    fetchPhonePlacementData()
    fetchWearableData()
    fetchWearablePlacementData()
    fetchReceivedSensorFiles()
    fetchPendingIssues()
}


export const RefParticipantsData = () => {
    const allParticipants = RefStoreValue(libCon.DATASET_PARTICIPANTS)
    const status = RefStoreValue(libCon.STATUS_DATASET_PARTICIPANTS)

    const [participants, setParticipants] = useState(() => null)

    useEffect(() => {

        setParticipants(dm_fun.filterOnlySEWA({ allParticipants }))
    }, [allParticipants])


    return ([status, participants])
}

export const RefParticipantsDeploymentData = () => {
    const allParticipants = RefStoreValue(libCon.DATASET_PARTICIPANTS)
    const status = RefStoreValue(libCon.STATUS_DATASET_PARTICIPANTS)

    const [participantsByDate, setParticipantsByDate] = useState(() => null)

    useEffect(() => {

        if (allParticipants !== null && allParticipants !== undefined) {
            let par = dm_fun.filterOnlySEWA({ allParticipants })
            par = dm_fun.filterOnlyActive({ participants: par })
            setParticipantsByDate(dm_fun.computeParticipantsByDate(par))
        }


    }, [allParticipants])


    return ([status, participantsByDate])
}

export const RefActiveParticipantsWithSensorData = () => {
    // Participants
    const allParticipants = RefStoreValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoreValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const sensors = RefStoreValue(libCon.DATASET_SENSORS)
    const statusSensors = RefStoreValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoreValue(libCon.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoreValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)


    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null && sensors !== null && sensorPlacements !== null) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })
            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachSensorPlacement({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            setParticipants(newParticipants)

        }

    }, [allParticipants, sensors, sensorPlacements])


    useEffect(() => {

        if (statusParticipants === libCon.LOADING || statusSensors === libCon.LOADING || statusSensorPlacement === libCon.LOADING)
            setStatus(libCon.LOADING)
        else if (statusParticipants === libCon.ERROR || statusSensors === libCon.ERROR || statusSensorPlacement === libCon.ERROR)
            setStatus(libCon.LOADING)
        else if (statusParticipants === libCon.OK && statusSensors === libCon.OK && statusSensorPlacement === libCon.OK)
            setStatus(libCon.OK)


    }, [statusParticipants, statusSensors, statusSensorPlacement])



    return ([status, participants])
}



export const RefActiveParticipantsWithAllPlacements = () => {

    // Participants
    const allParticipants = RefStoreValue(libCon.DATASET_PARTICIPANTS)
    const statusParticipants = RefStoreValue(libCon.STATUS_DATASET_PARTICIPANTS)

    // Sensors
    const sensors = RefStoreValue(libCon.DATASET_SENSORS)
    const statusSensors = RefStoreValue(libCon.STATUS_DATASET_SENSORS)

    // Sensor Placement
    const sensorPlacements = RefStoreValue(libCon.DATASET_SENSOR_HOUSE_PLACEMENT)
    const statusSensorPlacement = RefStoreValue(libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT)

    // Wearables
    const wearables = RefStoreValue(libCon.DATASET_WEARABLES)
    const statusWearables = RefStoreValue(libCon.STATUS_DATASET_WEARABLES)

    // Wearable Placement
    const wearablesPlacements = RefStoreValue(libCon.DATASET_WEARABLE_PLACEMENT)
    const statusWearablesPlacement = RefStoreValue(libCon.STATUS_DATASET_WEARABLE_PLACEMENT)

    // Phones
    const phones = RefStoreValue(libCon.DATASET_PHONES)
    const statusPhones = RefStoreValue(libCon.STATUS_DATASET_PHONES)

    // Phone Placement
    const phonePlacements = RefStoreValue(libCon.DATASET_PHONE_PLACEMENT)
    const statusPhonePlacement = RefStoreValue(libCon.STATUS_DATASET_PHONE_PLACEMENT)

    // Received Sensor Files
    const receivedSensorFiles = RefStoreValue(libCon.DATASET_RECEIVED_SENSOR_FILES)
    const statusReceivedSensorFiles = RefStoreValue(libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES)

    // Received Sensor Files
    const issues = RefStoreValue(libCon.DATASET_ISSUES)
    const statusIssues = RefStoreValue(libCon.STATUS_DATASET_ISSUES)

    const [participants, setParticipants] = useState(() => null)
    const [status, setStatus] = useState(() => libCon.NOT_STARTED)


    useEffect(() => {

        if (allParticipants !== null &&
            sensors !== null &&
            sensorPlacements !== null &&
            wearables !== null &&
            wearablesPlacements !== null &&
            phones !== null &&
            phonePlacements !== null &&
            receivedSensorFiles !== null &&
            issues !== null) {
            let newParticipants = dm_fun.filterOnlySEWA({ allParticipants: structuredClone(allParticipants) })

            newParticipants = dm_fun.filterOnlyActive({ participants: newParticipants })
            newParticipants = dm_fun.attachIssuesToParticipant({ participants: newParticipants, issues: issues })
            newParticipants = dm_fun.attachSensorPlacement({ participants: newParticipants, sensors: structuredClone(sensors), sensorPlacements: structuredClone(sensorPlacements) })
            newParticipants = dm_fun.attachReceivedSensorFiles({ participants: newParticipants, receivedSensorFiles: structuredClone(receivedSensorFiles) })
            newParticipants = dm_fun.attachPhonePlacement({ participants: newParticipants, phones: phones, phonePlacement: phonePlacements })
            newParticipants = dm_fun.attachWearablePlacement({ participants: newParticipants, wearables: wearables, wearablePlacement: wearablesPlacements })
            newParticipants = dm_fun.computeDaysUntilExpiry({ participants: newParticipants })
            newParticipants = dm_fun.computeDaysSinceLastUpload({ participants: newParticipants })
            newParticipants = dm_fun.computeDeviceCollectionStatus({ participants: newParticipants })


            setParticipants(newParticipants)

        }

    }, [allParticipants, sensors, sensorPlacements, wearables, wearablesPlacements, phones, phonePlacements, receivedSensorFiles, issues])


    useEffect(() => {

        if (statusParticipants === libCon.LOADING ||
            statusSensors === libCon.LOADING ||
            statusSensorPlacement === libCon.LOADING ||
            statusWearables === libCon.LOADING ||
            statusWearablesPlacement === libCon.LOADING ||
            statusPhones === libCon.LOADING ||
            statusPhonePlacement === libCon.LOADING ||
            statusReceivedSensorFiles === libCon.LOADING ||
            statusIssues === libCon.LOADING)
            setStatus(libCon.LOADING)
        else if (statusParticipants === libCon.ERROR ||
            statusSensors === libCon.ERROR ||
            statusSensorPlacement === libCon.ERROR ||
            statusWearables === libCon.ERROR ||
            statusWearablesPlacement === libCon.ERROR ||
            statusPhones === libCon.ERROR ||
            statusPhonePlacement === libCon.ERROR ||
            statusReceivedSensorFiles === libCon.ERROR ||
            statusIssues === libCon.ERROR)
            setStatus(libCon.LOADING)
        else if (statusParticipants === libCon.OK &&
            statusSensors === libCon.OK &&
            statusSensorPlacement === libCon.OK &&
            statusWearables === libCon.OK &&
            statusWearablesPlacement === libCon.OK &&
            statusPhones === libCon.OK &&
            statusPhonePlacement === libCon.OK &&
            statusReceivedSensorFiles === libCon.OK &&
            statusIssues === libCon.OK)
            setStatus(libCon.OK)


    }, [statusParticipants, statusSensors, statusSensorPlacement, statusWearables, statusWearablesPlacement, statusPhones, statusPhonePlacement, statusReceivedSensorFiles, statusIssues])



    return ([status, participants])
}


