// General functions
import { formatDistanceGivenDays } from "./dateFunctions";
import * as libCon from '../Constants';



export const arrayToDict = (array, idCol) => {
  let dictionary = array.reduce((acc, obj) => {
    acc[obj[idCol]] = obj;
    return acc;
  }, {});

  return dictionary
}

export const getColorOfExpiry = (days) => {
  if (days <= 0)
    return "var(--warning-color-1)"
  if (days <= 4)
    return "#CD5700"
  if (days <= 7)
    return "#E47600"
  if (days < 12)
    return "black"

  return "var(--success-color-2)"
}

export const getColorOfLostDays = (days) => {
  if (days <= 0)
    return "var(--warning-color-1)"

  return "var(--success-color-2)"
}


export const getTextOfExpiry = (days) => {

  if (days <= 0)
    return "Now"

  return formatDistanceGivenDays(days)

}

export const getColorOfLastUpload = (days) => {
  if (days >= 20)
    return "var(--warning-color-1)"
  if (days > 16)
    return "#CD5700"
  if (days > 13)
    return "#E47600"
  if (days > 8)
    return "black"

  return "var(--success-color-2)"
}




export const sumArray = (arr) => {

  const sumReducer = (accumulator, currentValue) => accumulator + currentValue;
  return (arr.reduce(sumReducer, 0))
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}


export const filterObjectByKeys = (obj, keys) => {
  return keys.reduce((filteredObj, key) => {
    if (key in obj) {
      filteredObj[key] = obj[key];
    }
    return filteredObj;
  }, {});
};


export const isNumeric = (value) => {
  return typeof value === 'number' && !isNaN(value);
}

export const isNull = (val) => {
  return (val === null || val === undefined || val === "")
}


export function isNullOrUndefined(value) {
  return value === null || value === undefined;
}

export function isNullOrUndefinedOrEmpty(value) {
  return value === null || value === undefined || value === "" || (Array.isArray(value) && value.length === 0);
}

export function isNullOrUndefinedOrEmptyOrMissing(value) {
  return value === null || value === undefined || value === "" || value === libCon.MISSING || (Array.isArray(value) && value.length === 0);
}



export const generateRandomAlphaNumeric = (length) => {
  const alphanumericChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += alphanumericChars.charAt(Math.floor(Math.random() * alphanumericChars.length));
  }
  return result;
};


export function hasNullOrUndefinedValues(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined) {
        return true; // Found a null or undefined value
      }
    }
  }
  return false; // No null or undefined values found
}

export const getNullOrUndefined = (obj) => {
  let response = []
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (obj[key] === null || obj[key] === undefined) {
        response.push(key)
      }
    }
  }
  return response

}


export const checkSewaId = (input) => {


  // Check if input is a string
  if (typeof input !== 'string' || input === "") {
    return false;
  }

  if (input.toUpperCase() === libCon.MISSING)
    return true

  // Check if input has length 14
  if (input.length !== 14) {
    return false;
  }

  // Extract the first 8 characters as the date string
  var dateString = input.substring(0, 8);

  // Check if the first 8 characters form a valid date
  var year = dateString.substring(0, 4);
  var month = dateString.substring(4, 6);
  var day = dateString.substring(6, 8);
  var date = new Date(year, month - 1, day);

  // Check if the date is valid and within the specified range
  var currentDate = new Date();
  var minDate = new Date('2018-01-01');

  if (isNaN(date.getTime()) || date < minDate || date > currentDate) {
    return false;
  }

  return true;

}



export const isValidPhoneNumber = (input) => {
  // Regular expression to match a 10-digit phone number
  // The pattern expects 10 digits and no other characters
  var phoneNumberPattern = /^\d{10}$/;

  // Test the input against the regular expression
  return phoneNumberPattern.test(input);
}


export function goToThirdPartyLink(link) {
  window.open(link, '_blank', 'noopener,noreferrer');

}

export function getRandomPositionId(length = 8) {
  return Math.random().toString(36).slice(-length);
} 