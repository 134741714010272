// Local constants
// Pages
export const PAGE_START = "/"
export const PAGE_DASHBOARD_EXPIRY_PARTICIPANTS = "/expiry_participants"
export const PAGE_DASHBOARD_DEPLOYMENT_STATUS = "/deployment_status"
export const PAGE_DASHBOARD_COLLECTION_STATUS = "/collection_status"
export const PAGE_DASHBOARD_DEPLOYMENT_PROGRESS = "/deployment_progress"
export const PAGE_DASHBOARD_DATA_STREAM_QUALITY = "/data_stream_quality"
export const PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS = "/single_collection_status"



// Chart Constants
export const PARTICIPANTS = "participants"

export const STORE = "STORE"

export const DEBUG = false
export const PASSWORD = "sewa@1972"

export const TARGET_PARTICIPANTS = 100


// Store Actions
export const SET_VALUE = "SET_VALUE"
export const UPDATE_OBJECT_VALUE = "UPDATE_OBJECT_VALUE"
export const RESET_ALL_DATASETS = "RESET_ALL_DATASETS"




// Store Values
// Variables
export const LOGGED_IN = "LOGGED_IN"
export const DOWNLOADING_PARTICIPANTS = "DOWNLOADING_PARTICIPANTS"
export const SELECTED_PARTICIPANT = "SELECTED_PARTICIPANT"



// Summary Constants



// Data Stream Quality Constants
export const SUMMARY_COVERAGE_INTERVALS = "coverage_intervals"
export const SUMMARY_ACCURACY_INTERVALS = "accuracy_intervals"
export const SUMMARY_HEART_RATE = "heart_rate"
export const SUMMARY_TEMPERATURE = "temperature"
export const SUMMARY_GEOLOCATION = "geolocation"
export const SUMMARY_SLEEP = "sleep"



