// Data Manipulation Functions
import { addDays, eachDayOfInterval, parseISO, startOfDay } from 'date-fns';
import { filterObject } from '../hooks/GeneralStoreHooks';
import * as libCon from '../community-hats-js-library/Constants';
import * as locCon from '../LocalConstants'
import * as dt_fun from '../community-hats-js-library/utils/dateFunctions';
import * as ds_fun from '../community-hats-js-library/utils/devicesStatusFunctions';
import { getExpiryDaysByBrand } from '../community-hats-js-library/utils/sensorFunctions';



// Only SEWA participants
export const filterOnlySEWA = ({ allParticipants }) => {
    if (allParticipants === null)
        return null

    let participants = filterObject(allParticipants, pa => pa[libCon.ATF_TYPE] === "SEWA" && !(pa[libCon.ATF_PUBLIC_ID].toUpperCase().includes("TEST")))
    return participants
}

export const filterOnlyActive = ({ participants }) => {
    if (participants === null)
        return null
    let newParticipants = filterObject(participants, pa => pa[libCon.ATF_ACTIVE] === "Yes")
    return newParticipants
}


export const attachSensorPlacement = ({ participants, sensors, sensorPlacements }) => {
    if (participants === null || sensors === null || sensorPlacements === null ||
        participants === undefined || sensors === undefined || sensorPlacements === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_SENSORS] = false
        par[libCon.ATF_TOTAL_ACTIVE_SENSORS] = 0
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            par[libCon.ATF_SENSOR_PLACEMENTS] = par[libCon.ATF_SENSOR_PLACEMENTS].map(id => {
                return ({ ...sensorPlacements[id], [libCon.ATF_SENSOR]: { ...sensors[sensorPlacements[id][libCon.ATF_SENSOR]] } })
            })


            par[libCon.ATF_ACTIVE_SENSORS] = []
            par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).forEach(sp => {
                let s = sp[libCon.ATF_SENSOR]
                s[libCon.ATF_START_DATE] = sp[libCon.ATF_START_DATE]
                par[libCon.ATF_ACTIVE_SENSORS].push(s)
            })

            par[libCon.ATF_HAS_ACTIVE_SENSORS] = par[libCon.ATF_ACTIVE_SENSORS].length > 0
            par[libCon.ATF_TOTAL_ACTIVE_SENSORS] = par[libCon.ATF_ACTIVE_SENSORS].length

        }

    })

    return participants

}

export const attachPhonePlacement = ({ participants, phones, phonePlacement }) => {
    if (participants === null || phones === null || phonePlacement === null ||
        participants === undefined || phones === undefined || phonePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_PHONES] = false
        if (libCon.ATF_PHONE_PLACEMENTS in par) {
            par[libCon.ATF_PHONE_PLACEMENTS] = par[libCon.ATF_PHONE_PLACEMENTS].map(id => {
                return ({ ...phonePlacement[id], [libCon.ATF_PHONE]: { ...phones[phonePlacement[id][libCon.ATF_PHONE]] } })
            })

            par[libCon.ATF_HAS_ACTIVE_PHONES] = par[libCon.ATF_PHONE_PLACEMENTS].some(s => s[libCon.ATF_ACTIVE] === libCon.YES)
            par[libCon.ATF_ACTIVE_PHONE] = null
            if (par[libCon.ATF_HAS_ACTIVE_PHONES])
                par[libCon.ATF_ACTIVE_PHONE] = par[libCon.ATF_PHONE_PLACEMENTS].filter(pp => pp[libCon.ATF_ACTIVE] === libCon.YES)[0][libCon.ATF_PHONE]

        }

    })

    return participants

}


export const attachWearablePlacement = ({ participants, wearables, wearablePlacement }) => {
    if (participants === null || wearables === null || wearablePlacement === null ||
        participants === undefined || wearables === undefined || wearablePlacement === undefined)
        return participants


    Object.values(participants).forEach(par => {

        par[libCon.ATF_HAS_ACTIVE_WEARABLES] = false
        if (libCon.ATF_WEARABLE_PLACEMENT in par) {
            par[libCon.ATF_WEARABLE_PLACEMENT] = par[libCon.ATF_WEARABLE_PLACEMENT].map(id => {
                return ({ ...wearablePlacement[id], [libCon.ATF_WEARABLE]: { ...wearables[wearablePlacement[id][libCon.ATF_WEARABLE]] } })
            })

            par[libCon.ATF_HAS_ACTIVE_WEARABLES] = par[libCon.ATF_WEARABLE_PLACEMENT].some(s => s[libCon.ATF_ACTIVE] === libCon.YES)
            par[libCon.ATF_ACTIVE_WEARABLE] = null
            if (par[libCon.ATF_HAS_ACTIVE_WEARABLES])
                par[libCon.ATF_ACTIVE_WEARABLE] = par[libCon.ATF_WEARABLE_PLACEMENT].filter(wp => wp[libCon.ATF_ACTIVE] === libCon.YES)[0]

        }

    })

    return participants

}


export const attachReceivedSensorFiles = ({ participants, receivedSensorFiles }) => {
    if (participants === null || receivedSensorFiles === null ||
        participants === undefined || receivedSensorFiles === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            par[libCon.ATF_SENSOR_PLACEMENTS].forEach(sp => {

                if (libCon.ATF_RECEIVED_SENSOR_FILES in sp[libCon.ATF_SENSOR])
                    sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES] = sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES].filter(rsk => rsk in receivedSensorFiles).map(rsk => receivedSensorFiles[rsk])
                else
                    sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES] = []

            })
        }

    })

    return participants

}



export const attachIssuesToParticipant = ({ participants, issues }) => {
    if (participants === null || issues === null ||
        participants === undefined || issues === undefined)
        return participants


    Object.values(participants).forEach(par => {
        if (libCon.ATF_ISSUES in par) {
            par[libCon.ATF_ISSUES] = par[libCon.ATF_ISSUES].filter(i => i in issues).map(i => issues[i])
            par[libCon.ATF_GROUND_TEAM_ISSUES] = par[libCon.ATF_ISSUES].filter(i => i[libCon.ATF_RESPONSIBLE] === libCon.ATF_GROUND_TEAM)
        }

        else {
            par[libCon.ATF_ISSUES] = []
            par[libCon.ATF_GROUND_TEAM_ISSUES] = []
        }


    })

    return participants

}



export const computeDaysUntilExpiry = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {
        par[libCon.ATF_DAYS_UNTIL_EXPIRE] = null

        if (libCon.ATF_SENSOR_PLACEMENTS in par) {
            let arr = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
                let sns = sp[libCon.ATF_SENSOR]
                let expDays = getExpiryDaysByBrand(sns[libCon.ATF_BRAND])

                let daysUntilExpire = expDays - dt_fun.getDaysBetweenDates(sp[libCon.ATF_START_DATE], now)
                if (sns !== undefined && libCon.ATF_LATEST_RECORD in sns)
                    daysUntilExpire = Math.max(daysUntilExpire, expDays - dt_fun.getDaysBetweenDates(sns[libCon.ATF_LATEST_RECORD], now))

                return daysUntilExpire

            })


            par[libCon.ATF_DAYS_UNTIL_EXPIRE] = Math.min(...arr)

        }

    })

    return participants


}



export const computeDaysSinceLastUpload = ({ participants }) => {

    if (participants === null)
        return (participants)

    const now = new Date()

    Object.values(participants).forEach((par, i) => {
        par[libCon.ATF_DAYS_SINCE_LAST_UPLOAD] = null
        par[libCon.ATF_LATEST_COLLECTION_DATE] = new Date(par[libCon.ATF_DATA_START])
        if (libCon.ATF_SENSOR_PLACEMENTS in par) {

            // Days since last Upload
            let arr = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
                let sns = sp[libCon.ATF_SENSOR]

                let daysSinceLastUpload = dt_fun.getDaysBetweenDates(sp[libCon.ATF_START_DATE], now)
                if (sns !== undefined && libCon.ATF_LAST_UPLOADED in sns)
                    daysSinceLastUpload = Math.min(daysSinceLastUpload, dt_fun.getDaysBetweenDates(sns[libCon.ATF_LAST_UPLOADED], now))

                return daysSinceLastUpload

            })

            par[libCon.ATF_DAYS_SINCE_LAST_UPLOAD] = Math.min(...arr)

            // Last collection Date
            let arrDates = par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).map(sp => {
                let sns = sp[libCon.ATF_SENSOR]

                let lastCollectionDate = new Date(sp[libCon.ATF_START_DATE])
                if (sns !== undefined && libCon.ATF_LAST_UPLOADED in sns)
                    lastCollectionDate = Math.max(lastCollectionDate, new Date(sns[libCon.ATF_LAST_UPLOADED]))

                return lastCollectionDate

            })

            par[libCon.ATF_LATEST_COLLECTION_DATE] = Math.max(...arrDates)

        }

    })

    return participants


}



export const computeDeviceCollectionStatus = ({ participants }) => {

    if (participants === null)
        return (participants)



    Object.values(participants).forEach((par, i) => {

        // Intake Survey
        let [intakeStatus, intakeMessage] = ds_fun.getIntakeSurveyStatus(par[libCon.ATF_INTAKE_STATUS])

        par[libCon.ATF_INTAKE_SURVEY] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: intakeStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: intakeMessage
        }


        // Perceptual Survey
        let [perceptualStatus, perceptualMessage] = ds_fun.getPerceptualSurveyStatus(par[libCon.ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY], par[libCon.ATF_LATEST_COLLECTION_DATE])

        par[libCon.ATF_PERCEPTUAL_SURVEY] = {
            [libCon.ATF_DEVICE_COLLECTION_STATUS]: perceptualStatus,
            [libCon.ATF_DEVICE_COLLECTION_MESSAGE]: perceptualMessage
        }

        // Phone
        if (par[libCon.ATF_HAS_ACTIVE_PHONES]) {
            // if(par[libCon.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")

            let [status, message] = ds_fun.getPhonePlacementStatus(par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_LAST_UPLOADED], par[libCon.ATF_LATEST_COLLECTION_DATE])
            par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
            par[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Wearable
        if (par[libCon.ATF_HAS_ACTIVE_WEARABLES]) {
            // if(par[libCon.AT_SEWA_ID] === "20220331000367")
            //     console.log("YES")

            let [status, message] = ds_fun.getWearablePlacementStatus(par[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_LAST_UPLOADED], par[libCon.ATF_LATEST_COLLECTION_DATE])
            par[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
            par[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message

        }

        // Sensors
        if (par[libCon.ATF_HAS_ACTIVE_SENSORS]) {


            // if(par[libCon.AT_SEWA_ID] === "20240226038551")
            //     console.log("YES")

            par[libCon.ATF_SENSOR_PLACEMENTS].filter(sp => sp[libCon.ATF_ACTIVE] === libCon.YES).forEach(sp => {

                // if(sp[libCon.AT_SENSOR][libCon.AT_SERIAL] === "SY51D40408730")
                //     console.log("Yes")

                let [status, message] = ds_fun.getSensorPlacementStatus(sp[libCon.ATF_SENSOR][libCon.ATF_BRAND], sp[libCon.ATF_SENSOR][libCon.ATF_MODEL], sp[libCon.ATF_SENSOR][libCon.ATF_RECEIVED_SENSOR_FILES], par[libCon.ATF_LATEST_COLLECTION_DATE], sp[libCon.ATF_START_DATE], sp[libCon.ATF_SENSOR][libCon.ATF_LATEST_BATTERY_CHANGE])
                sp[libCon.ATF_SENSOR][libCon.ATF_DEVICE_COLLECTION_STATUS] = status
                sp[libCon.ATF_SENSOR][libCon.ATF_DEVICE_COLLECTION_MESSAGE] = message


            })

        }

        // For all sensors
        let [sensorStatus, sensorMessage] = ds_fun.getUnifyingStatusAndMessageForSensors(par[libCon.ATF_ACTIVE_SENSORS])
        par[libCon.AT_SENSORS_COLLECTION_STATUS] = sensorStatus
        par[libCon.AT_SENSORS_COLLECTION_MESSAGE] = sensorMessage

        // For Participant
        let [participantStatus, participantMessage] = ds_fun.getUnifyingStatusAndMessageForParticipant(par)
        par[libCon.AT_COLLECTION_STATUS] = participantStatus
        par[libCon.AT_COLLECTION_MESSAGE] = participantMessage


    })

    return participants


}





export const computeParticipantsByDate = (participants) => {
    let summary = {}

    let minDate = null

    Object.values(participants).forEach((par, i) => {
        let start_date = startOfDay(parseISO(par[libCon.ATF_JOINED_DATE])).getTime()

        if (!(start_date in summary))
            summary[start_date] = 0

        summary[start_date] += 1

        if (minDate === null)
            minDate = start_date
        else
            minDate = Math.min(start_date, minDate)


    })

    const dates = eachDayOfInterval({ start: new Date(minDate), end: addDays(new Date(), 1) });

    // Iterate over the dates and perform some action
    dates.forEach(date => {
        if (!(date.getTime() in summary))
            summary[date.getTime()] = 0
    });

    let arr = Object.keys(summary).map(k => { return ({ [locCon.PARTICIPANTS]: summary[k], [libCon.DATE]: parseInt(k) }) })
    arr.sort((a, b) => a[[libCon.DATE]] - b[[libCon.DATE]]);

    return arr

}