// Library Constants

import config_dev from "./config/config_development.json"
import config_prod from "./config/config_production.json"


// Development
export const DEVELOPMENT = false


export const CONFIG = DEVELOPMENT ? config_dev : config_prod

// AirTable Constants
export const AT_API_KEY = "airtable_key"
export const AT_APP_ID = "at_app_id"



// Fitbit API
export const FITBIT_TEMP_APPLICATION_ID = "23RSS3"
export const FITBIT_FINAL_APPLICATION_ID = "23S2FK"
export const FITBIT_API_SEP = "---"
// Defaults
export const DEFAULT_PHONE_NUMBER = "0000000000"


// Sensor Inbox
export const SENSOR_INBOX_EMAIL = "sensor.inbox@community-hats.net"


// Status
export const STATUS_DATASET_PARTICIPANTS = "STATUS_DATASET_PARTICIPANTS"
export const STATUS_DATASET_SENSORS = "STATUS_DATASET_SENSORS"
export const STATUS_DATASET_HOUSES = "STATUS_DATASET_HOUSES"
export const STATUS_DATASET_PARTICIPANT_PLACEMENT = "STATUS_DATASET_PARTICIPANT_PLACEMENT"
export const STATUS_DATASET_SENSOR_HOUSE_PLACEMENT = "STATUS_DATASET_SENSOR_HOUSE_PLACEMENT"
export const STATUS_DATASET_WEARABLES = "STATUS_DATASET_WEARABLES"
export const STATUS_DATASET_PHONES = "STATUS_DATASET_PHONES"
export const STATUS_DATASET_WEARABLE_PLACEMENT = "STATUS_DATASET_WEARABLE_PLACEMENT"
export const STATUS_DATASET_PHONE_PLACEMENT = "STATUS_DATASET_PHONE_PLACEMENT"
export const STATUS_DATASET_RECEIVED_SENSOR_FILES = "STATUS_DATASET_RECEIVED_SENSOR_FILES"
export const STATUS_DATASET_ISSUES = "STATUS_DATASET_ISSUES"



// Datasets
export const DATASET_PARTICIPANTS = "DATASET_PARTICIPANTS"
export const DATASET_SENSORS = "DATASET_SENSORS"
export const DATASET_HOUSES = "DATASET_HOUSES"
export const DATASET_WEARABLES = "DATASET_WEARABLES"
export const DATASET_PHONES = "DATASET_PHONES"
export const DATASET_PARTICIPANT_PLACEMENT = "DATASET_PARTICIPANT_PLACEMENT"
export const DATASET_WEARABLE_PLACEMENT = "DATASET_WEARABLE_PLACEMENT"
export const DATASET_PHONE_PLACEMENT = "DATASET_PHONE_PLACEMENT"
export const DATASET_SENSOR_HOUSE_PLACEMENT = "DATASET_SENSOR_HOUSE_PLACEMENT"
export const DATASET_RECEIVED_SENSOR_FILES = "DATASET_RECEIVED_SENSOR_FILES"
export const DATASET_ISSUES = "DATASET_ISSUES"




// General Contants
export const ID = "id"
export const VALUE = "value"
export const NOT_STARTED = "not_started"
export const ERROR = "error"
export const LOADING = "loading"
export const OK = "ok"
export const NOT_FOUND = "not_found"
export const KEY = "key"
export const DATE = "date"
export const YES = "Yes"
export const NO = "No"
export const MISSING = "MISSING"
export const START_DATE = "start_date"
export const END_DATE = "end_date"
export const DURATION = "duration"
export const COVERAGE = "coverage"
export const INFO = "info"
export const TEXT = "text"
export const LINK = "link"
export const CONTAINER = "container"
export const STATUS = "status"
export const FIELDS = "fields"
export const POSITION_ID = "position_id"
export const OBJECT = "object"
export const LOADING_INFO = "loading_info"
export const DONE = "done"
export const MESSAGE = "message"
export const TABLE = "table"
export const NECESSARY_IDS = "necessary_ids"
export const OPTIONAL_IDS = "optional_ids"
export const DERIVATE_IDS = "derivate_ids"
export const SINGLE_DEPENDENCIES = "single_dependencies"
export const SET_DEPENDENCIES = "array_dependencies"
export const IS_INSIDE_SET = "is_inside_array"
export const SEARCH = "search"
export const CODE = "code"




// Sensor Brands
export const KESTREL = "Kestrel"
export const GOVEE = "Govee"
export const HOBO = "Hobo"
// Models
export const U12_HOBO_MODEL = "U12-013"
export const MX_HOBO_MODEL = "MX1104"



// Languages
export const EN = "en"
export const GU = "gu"
export const ES = "es"


export const ATF_ACTIVATION_CODE = "Activation Code"
export const ATF_DATE_CREATED = "Date Created"
export const ATF_SERIAL = "Serial"
export const ATF_PUBLIC_ID = "ID"
export const ATF_AREA = "Area"
export const ATF_SENSOR = "Sensor"
export const ATF_PHONE = "Phone"
export const ATF_WEARABLE = "Wearable"
export const ATF_LOCATION = "Location"
export const ATF_LATITUDE = "Latitude"
export const ATF_LONGITUDE = "Longitude"
export const ATF_ACCURACY = "Accuracy"
export const ATF_LAST_UPLOADED = "Last Uploaded"
export const ATF_LAST_MIGRATION = "Last Migration"
export const ATF_LATEST_RECORD = "Latest Record"
export const ATF_BRAND = "Brand"
export const ATF_MODEL = "Model"
export const ATF_UPLOADED_DATE = "Date Uploaded"
export const ATF_START_DATE = "Start Date"
export const ATF_END_DATE = "End Date"
export const ATF_TAG_COLOR = "Tag Color"
export const ATF_TAG_CODE = "Tag Code"
export const ATF_SEWA_ID = "SEWA Id"
export const ATF_TYPE = "Type"
export const ATF_SENSOR_PLACEMENTS = "Sensor Placements"
export const ATF_PHONE_PLACEMENTS = "Phone Placement"
export const ATF_WEARABLE_PLACEMENT = "Wearable Placement"
export const ATF_HAS_ACTIVE_SENSORS = "Has Active Sensors"
export const ATF_ACTIVE_SENSORS = "Active Sensors"
export const ATF_TOTAL_ACTIVE_SENSORS = "Total Active Sensors"
export const ATF_HAS_ACTIVE_WEARABLES = "Has Active Wearables"
export const ATF_ACTIVE_WEARABLE = "Active Wearable"
export const ATF_HAS_ACTIVE_PHONES = "Has Active Phones"
export const ATF_ACTIVE_PHONE = "Active Phone"
export const ATF_RECEIVED_SENSOR_FILES = "Received Sensor Files"
export const ATF_NAME = "Name"
export const ATF_LAST_NAME = "Last Name"
export const ATF_PHONE_NUMBER = "Phone Number"
export const ATF_NOTES = "Notes"
export const ATF_DAYS_UNTIL_EXPIRE = "Days Until Expire"
export const ATF_DAYS_SINCE_LAST_UPLOAD = "Days Since Last Upload"
export const ATF_LATEST_COLLECTION_DATE = "Latest Collection Date"
export const ATF_DATA_START = "Data Start"
export const ATF_ACTIVE = "Active"
export const ATF_JOINED_DATE = "Joined Date"
export const ATF_DATE_UPLOADED = "Date Uploaded"
export const ATF_SIZE_IN_DAYS = "Size in Days"
export const ATF_FITBIT_TOKEN = "Fitbit Token"
export const ATF_FITBIT_REQUEST_ID = "Fitbit Request ID"
export const ATF_EMAIL = "Email"
export const ATF_ACCOUNT_EMAIL = "Account Email"
export const ATF_DEVICE_COLLECTION_STATUS = "Device Collection Status"
export const ATF_DEVICE_COLLECTION_MESSAGE = "Device Collection Message"
export const ATF_LATEST_BATTERY_CHANGE = "Latest Battery Change"
export const ATF_STATUS = "Status"
export const ATF_PENDING = "Pending"
export const ATF_DONE = "Done"
export const ATF_ISSUES = "Issues"
export const ATF_RESPONSIBLE = "Responsible"
export const ATF_GROUND_TEAM_ISSUES = "Ground Team Issues"
export const ATF_GROUND_TEAM = "Ground Team"
export const ATF_NUMERIC_ID = "Numeric ID"
export const ATF_TAGS = "Tags"
export const ATF_DESCRIPTION = "Description"
export const ATF_ACTION = "Action"
export const ATF_DATE_SOLVED = "Date Solved"
export const ATF_INTAKE_STATUS = "Intake Status"
export const ATF_PERCEPTUAL_STATUS = "Perceptual Status"
export const ATF_REQUIRED = "Required"
export const ATF_COMPLETE = "Complete"
export const ATF_INTAKE_SURVEY = "Intake Survey"
export const ATF_PERCEPTUAL_SURVEY = "Perceptual Survey"
export const ATF_LAST_UPLOADED_PERCEPTUAL_SURVEY = "Last Uploaded (Perceptual Survey)"
export const ATF_PASSWORD = "Password"
export const ATF_DROPBOX_PASSWORD = "Dropbox Password"
export const ATF_GOVEE_PASSWORD = "Govee Password"
export const ATF_ID_NAME = "id"
export const ATF_PHONE_SERIAL = "Phone Serial"
export const ATF_PLACEMENT_START = "Placement Start Date"
export const ATF_FITBIT_CLIENT_ID = "Fitbit Client ID"
export const ATF_FITBIT_TOKEN_EXPIRY_DATE = "Fitbit Token Expiry Date"
export const ATF_PARTICIPANT = "Participant"
export const ATF_HOUSE = "House"
export const ATF_SENSORS = "Sensors"
export const ATF_PLACEMENT_PHOTO = "Placement Photo"
export const ATF_POSITION = "Position"
export const ATF_LOCATION_NUMERIC_ID = "Location Numeric ID"
export const ATF_SENSOR_SERIAL = "Sensor Serial"





// Status and Messages
export const AT_SENSORS_COLLECTION_STATUS = "Sensors Collection Status"
export const AT_SENSORS_COLLECTION_MESSAGE = "Sensors Collection Message"
export const AT_COLLECTION_STATUS = "Collection Status"
export const AT_COLLECTION_MESSAGE = "Collection Message"



// Airtable Tables
export const AT_TABLE_GOVEE_ACTIVATION_CODES_ID = "at_table_govee_activation_codes_id"
export const AT_TABLE_EMAILS = "at_table_emails"
export const AT_TABLE_PARTICIPANTS = "at_table_participants"
export const AT_TABLE_PHONES = "at_table_phones"
export const AT_TABLE_PHONE_PLACEMENTS = "at_phone_placements"
export const AT_TABLE_WEARABLE_PLACEMENTS = "at_wearable_placements"
export const AT_TABLE_WEARABLES = "at_wearables"
export const AT_TABLE_HOUSES = "at_houses"
export const AT_TABLE_PARTICIPANTS_PLACEMENT = "at_participant_placement"
export const AT_TABLE_SENSORS = "at_sensors"
export const AT_TABLE_SENSOR_HOUSE_PLACEMENT = "at_sensor_house_placement"
export const AT_TABLE_SENSOR_PLACEMENT_FORM = "at_sensor_placement_form"
export const AT_TABLE_RECEIVED_SENSOR_FILES = "at_received_sensor_files"
export const AT_TABLE_ISSUES = "at_issues"
export const AT_TABLE_BUNDLES = "at_tables_bundles"

// Views
export const AT_VIEW_PARTICIPANTS_PUBLIC = "Public"


// Devices
export const DEVICE_SENSORS = "Sensors"
export const DEVICE_PHONE = "Location Tracker"
export const DEVICE_WEARABLE = "Wearable"


// Device Status
export const DEVICE_STATUS_OK = "DEVICE_STATUS_OK"
export const DEVICE_STATUS_WAITING_COLLECTION = "DEVICE_STATUS_WAITING_COLLECTION"
export const DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA = "DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA"
export const DEVICE_STATUS_ERROR_IN_COLLECTION = "DEVICE_STATUS_ERROR_IN_COLLECTION"
export const DEVICE_STATUS_HARDWARE_MALFUNCTION = "DEVICE_STATUS_HARDWARE_MALFUNCTION"
export const DEVICE_STATUS_INCOMPLETE_DEPLOYMENT = "DEVICE_STATUS_INCOMPLETE_DEPLOYMENT"
export const DEVICE_STATUS_OTHER = "DEVICE_STATUS_OTHER"



// Messages
export const MESSAGE_DATA_UP_TO_DATE = "Data up to date"
export const MESSAGE_PLACEMENT_JUST_STARTED = "Data up to date (Placement just started, no data expected yet)"
export const MESSAGE_BATTERY_CHANGE_JUST_HAPPENED = "Data up to date (Batteries recently updated, no extensive data expected yet)"
export const MESSAGE_WAITING_FOR_COLLECTION = "Waiting for Collection"
export const MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA = "Waiting for Collection (Loosing Data)"
export const MESSAGE_DATA_WAS_NOT_COLLECTED = "Data was not Collected"
export const MESSAGE_NO_FILES_HAVE_BEEN_RECEIVED = "No files have been received"
export const MESSAGE_SENSOR_FILE_NOT_RECENT_ENOUGH = "Latest files not recent enough, please select a more recent end date for the export, or make sure the sensor has synched completely."
export const MESSAGE_SENSOR_FILE_NOT_LONG_ENOUGH = "Latest files not long enough, place select an earlier start date"
export const MESSAGE_NO_SENSORS_IN_BUNDLE = "No sensors in bundle."
export const MESSAGE_INCOMPLETE_DEPLOYMENT = "Incomplete Deployment"
export const MESSAGE_ERROR_IN_COLLECTION = "Error in collection"
export const MESSAGE_HANDLED_BY_CEPT = "Collection is Handled by the CEPT Team"
export const MESSAGE_MULTIPLE_INTAKES = "The participant has multiple active placements."
export const MESSAGE_NO_INTAKES = "The participant has no active placements."
export const MESSAGE_INTAKE_COMPLETE = "Intake survey up to date."
export const MESSAGE_INTAKE_WAITING = "Please administer the intake survey."



// Tag Colors
export const TAG_COLORS = ["gold", "magenta", "blue", "lime", "volcano", "geekblue", "red", "green", "cyan", "orange", "purple"]



// Airtable Object Sync Button Bheaviors
export const ATO_BEHAVIOR_PULL = "pull"
export const ATO_BEHAVIOR_CREATE = "create"
export const ATO_BEHAVIOR_UPDATE = "update"
export const ATO_BEHAVIOR_UPDATE_CREATE = "update_create"
export const ATO_BEHAVIOR_PULL_CREATE = "pull_create"
export const ATO_BEHAVIOR_SYNC = "sync"


export const COLORS = ["Green", "Yellow", "Red", "Pink", "Gray", "Orange", "White", "Blue", "Violet", "Brown"]

export const SENSOR_POSITIONS = ["Workplace", "Sleep Room", "Both (Sleep and Work)", "Kitchen", "Other"]


export const NETWORK_ERROR = "netrwork_error"
export const AIRTABLE_ERROR = "airtable_error"
export const NOT_FOUND_ERROR = "not_found_error"



export const ERRORS = {
    [ERROR]: {
        [MESSAGE]: "generalError",
        [CODE]: 1
    },
    [NETWORK_ERROR]: {
        [MESSAGE]: "networkError",
        [CODE]: 2
    },
    [AIRTABLE_ERROR]: {
        [MESSAGE]: "airtableError",
        [CODE]: 3
    },
    [NOT_FOUND_ERROR]: {
        [MESSAGE]: "notFoundError",
        [CODE]: 4
    }
}


