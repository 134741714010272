import React, { useEffect, useState } from 'react'
import { RefActiveParticipantsWithAllPlacements, refreshAllDatasets } from '../hooks/DatasetHooks'
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"
import { Button, Input, Space, Table } from "antd";
import { Typography } from 'antd';
import { getColorOfExpiry, getColorOfLastUpload, getColorOfLostDays, getTextOfExpiry } from '../community-hats-js-library/utils/generalFunctions';
import { formatDistanceGivenDays, formatDistanceShort } from '../community-hats-js-library/utils/dateFunctions';
import { SymbolForStatus } from '../community-hats-js-library/utils/devicesStatusFunctions';
import { ArrowRightOutlined } from '@ant-design/icons';
import { setValue } from '../store/actions/generalStoreActions';
import { RefSetCurrentPage } from '../hooks/NavigationHooks';
import LoadingDatasets from '../components/LoadingDatasets';

const { Text, Title } = Typography;

function DashboardExpiryParticipants() {

  // Navigation
  const setCurrentPage = RefSetCurrentPage()

  const goToParticipant = (id) => {
    setValue(locCon.SELECTED_PARTICIPANT, id)
    setCurrentPage(locCon.PAGE_DASHBOARD_SINGLE_COLLECTION_STATUS)
  }


  // Participants
  const [status, participants] = RefActiveParticipantsWithAllPlacements()

  const [participantArray, setParticipantArray] = useState([])


  const [inputValue, setInputValue] = useState(() => null)
  const [filterText, setFilterText] = useState(() => null)

  // Status
  const [isTableReady, setIsTableReady] = useState(false)

  useEffect(() => {

    setIsTableReady(status !== libCon.NOT_STARTED && status !== libCon.LOADING && participants !== null)
  }, [status, participants])


  useEffect(() => {

    if (participants !== null) {
      let parArray = Object.values(participants)

      if (filterText !== null && filterText !== undefined && filterText !== "")
        parArray = parArray.filter(ob => ob[libCon.ATF_PUBLIC_ID].toUpperCase().includes(filterText.toUpperCase()))

      setParticipantArray(parArray)
    }

  }, [participants, filterText])


  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const columns = [
    {
      title: <p>Collection<br />Status</p>,
      dataIndex: libCon.AT_COLLECTION_STATUS,
      key: 1,
      align: 'center',
      width: 100,
      fixed: "left",
      sorter: (a, b) => a[libCon.AT_COLLECTION_STATUS].localeCompare(b[libCon.AT_COLLECTION_STATUS]),
      render: (val, p) => <SymbolForStatus status={p[libCon.AT_COLLECTION_STATUS]} message={p[libCon.AT_COLLECTION_MESSAGE]} />
    },
    {
      title: 'Names',
      dataIndex: libCon.ATF_NAME,
      key: 2,
      align: 'center',
      width: 100,
      fixed: "left",
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{record[libCon.ATF_NAME] + " " + record[libCon.ATF_LAST_NAME]}</p>
    },
    {
      title: ' SEWA Id ',
      dataIndex: libCon.ATF_SEWA_ID,
      key: 3,
      align: 'center',
      width: 100,
      fixed: "left",
      render: (val, record) => <p style={{ fontSize: 12, textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
    },
    {
      title: 'Area',
      dataIndex: libCon.ATF_AREA,
      key: 4,
      align: 'center',
      width: 100,
      render: (val, record) => <p style={{ fontSize: 12, textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val}</p>
    },
    {
      title: <p>Next Collection</p>,
      dataIndex: libCon.ATF_DAYS_UNTIL_EXPIRE,
      key: 5,
      align: 'center',
      width: 100,
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a[libCon.ATF_DAYS_UNTIL_EXPIRE] - b[libCon.ATF_DAYS_UNTIL_EXPIRE],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfExpiry(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{getTextOfExpiry(val)}</p>

    },
    {
      title: <p>Last Collection</p>,
      dataIndex: libCon.ATF_LATEST_COLLECTION_DATE,
      key: 6,
      align: 'center',
      width: 100,
      sorter: (a, b) => a[libCon.ATF_DAYS_SINCE_LAST_UPLOAD] - b[libCon.ATF_DAYS_SINCE_LAST_UPLOAD],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLastUpload(record[libCon.ATF_DAYS_SINCE_LAST_UPLOAD]) }}>{formatDistanceShort(val)}</p>

    },
    {
      title: <p>Lost Data</p>,
      dataIndex: libCon.ATF_DAYS_UNTIL_EXPIRE,
      key: 7,
      align: 'center',
      width: 100,
      sorter: (a, b) => a[libCon.ATF_DAYS_UNTIL_EXPIRE] - b[libCon.ATF_DAYS_UNTIL_EXPIRE],
      render: (val, record) => <p style={{ textAlign: 'center', color: getColorOfLostDays(record[libCon.ATF_DAYS_UNTIL_EXPIRE]) }}>{val >= 0 ? "0 days" : formatDistanceGivenDays(val, false)}</p>

    },
    {
      title: <p>Sensor<br />Status</p>,
      dataIndex: libCon.ATF_HAS_ACTIVE_SENSORS,
      key: 8,
      align: 'center',
      width: 100,
      render: (val, record) => val ? <SymbolForStatus status={record[libCon.AT_SENSORS_COLLECTION_STATUS]} message={record[libCon.AT_SENSORS_COLLECTION_MESSAGE]} /> : <p></p>

    },
    {
      title: <p>Location Tracker<br />Status</p>,
      dataIndex: libCon.ATF_HAS_ACTIVE_PHONES,
      key: 9,
      align: 'center',
      width: 80,
      render: (val, record) => val ? <SymbolForStatus status={record[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_ACTIVE_PHONE][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} /> : <p></p>

    },
    {
      title: <p>Wearable<br />Status</p>,
      dataIndex: libCon.ATF_HAS_ACTIVE_WEARABLES,
      key: 10,
      align: 'center',
      width: 80,
      render: (val, record) => val ? <SymbolForStatus status={record[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_ACTIVE_WEARABLE][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} /> : <p></p>

    },
    {
      title: <p>Perceptual<br />Survey<br />Status</p>,
      dataIndex: libCon.ATF_PERCEPTUAL_SURVEY,
      key: 11,
      align: 'center',
      width: 100,
      render: (val, record) => val ? <SymbolForStatus status={record[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_PERCEPTUAL_SURVEY][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} /> : <p></p>

    },
    {
      title: <p>Intake<br />Survey<br />Status</p>,
      dataIndex: libCon.ATF_INTAKE_STATUS,
      key: 12,
      align: 'center',
      width: 80,
      render: (val, record) => val ? <SymbolForStatus status={record[libCon.ATF_INTAKE_SURVEY][libCon.ATF_DEVICE_COLLECTION_STATUS]} message={record[libCon.ATF_INTAKE_SURVEY][libCon.ATF_DEVICE_COLLECTION_MESSAGE]} /> : <p></p>

    },
    {
      title: <p>Pending<br />Issues</p>,
      dataIndex: libCon.ATF_GROUND_TEAM_ISSUES,
      key: 13,
      align: 'center',
      width: 80,
      render: (val, record) => <p>{val === null || val === undefined ? 0 : val.filter(i => i[libCon.ATF_STATUS] === libCon.ATF_PENDING).length}</p>

    },
    {
      title: 'Go To',
      dataIndex: libCon.ID,
      key: 14,
      align: 'center',
      width: 80,
      fixed: "right",
      render: (val) => <Button type="primary" onClick={() => goToParticipant(val)}><ArrowRightOutlined /></Button>,
    },
  ];





  return (
    <div className="mainContainer">
      <Title level={2}>{`Participant Data About to Expire`}</Title>
      <Space direction="vertical" >
        <Space direction="horizontal" >
          <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh All</Button>
          <Input placeholder="Filter" value={inputValue} onChange={handleInputChange} />
          <Button type="primary" onClick={() => setFilterText(inputValue)}>Filter</Button>
          <Button type="primary" onClick={() => { setInputValue(null); setFilterText(null) }} danger>Clear</Button>
        </Space>
        <Space direction="horizontal" size={"large"}>
          <Space direction="horizontal" >
            <SymbolForStatus status={libCon.DEVICE_STATUS_OK} message={libCon.MESSAGE_DATA_UP_TO_DATE} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_OK).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus status={libCon.DEVICE_STATUS_WAITING_COLLECTION} message={libCon.MESSAGE_WAITING_FOR_COLLECTION} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus status={libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA} message={libCon.MESSAGE_WAITING_FOR_COLLECTION_LOOSING_DATA} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_WAITING_COLLECTION_LOOSING_DATA).length}</Text>
          </Space>
          <Space direction="horizontal" >
            <SymbolForStatus status={libCon.DEVICE_STATUS_ERROR_IN_COLLECTION} message={libCon.MESSAGE_ERROR_IN_COLLECTION} />
            <Text style={{ fontSize: "0.8em", color: "var(--text-color)" }}>{!isTableReady ? "--" : participantArray.filter(p => p[libCon.AT_COLLECTION_STATUS] === libCon.DEVICE_STATUS_ERROR_IN_COLLECTION).length}</Text>
          </Space>
        </Space>

      </Space>

      <div style={{ maxWidth: "98vw", marginTop: "20px" }}>
        {
          !isTableReady
            ? <LoadingDatasets />
            : status === libCon.ERROR
              ? <div>Error</div>
              : <Table dataSource={participantArray} columns={columns} size="small" scroll={{ x: 'max-content', y: window.innerHeight * 0.65 }} pagination={{ pageSize: 100 }} />
        }
      </div>


    </div>
  )
}

export default DashboardExpiryParticipants