
import { notification } from "antd";

export const showNotification = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        duration: 6,
        style: {
            background: "var(--background-color-1-transparent)"
        }
    })

}

export const showError = (message) => {
    notification.open({
        message: message,
        showProgress: true,
        pauseOnHover: true,
        placement: "top",
        style: {
            background: "var(--primary-color-2-transparent)"
        }
    })


}