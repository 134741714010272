// Reducer for all the store
import * as libCon from "../../community-hats-js-library/Constants"
import * as locCon from "../../LocalConstants"



// Initial State
export const initialState = {
    // Status
    [libCon.STATUS_DATASET_PARTICIPANTS]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_SENSORS]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_HOUSES]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_PARTICIPANT_PLACEMENT]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES]: libCon.NOT_STARTED,
    [libCon.STATUS_DATASET_ISSUES]: libCon.NOT_STARTED,



    // Dataset
    [libCon.DATASET_PARTICIPANTS]: null,
    [libCon.DATASET_SENSORS]: null,
    [libCon.DATASET_HOUSES]: null,
    [libCon.DATASET_PARTICIPANT_PLACEMENT]: null,
    [libCon.DATASET_SENSOR_HOUSE_PLACEMENT]: null,
    [libCon.DATASET_RECEIVED_SENSOR_FILES]: null,
    [libCon.DATASET_ISSUES]: null,


    // Variables
    [locCon.LOGGED_IN]: false,
    [locCon.DOWNLOADING_PARTICIPANTS]: false,
    [locCon.SELECTED_PARTICIPANT]: null

}



/**
 * Main actions function.
 * The structure of the function is a main switch with a final clean-up section at the end
 * @param {object} state - Current state of the store   
 * @param {object} action  - Object containing the action and the payload 
 * @returns The new state
 */
export default function setStateFun(state = initialState, action) {


    switch (action.type) {
        case locCon.SET_VALUE:
            state = { ...state, [action.payload[libCon.ID]]: action.payload[libCon.VALUE] }
            break

        case locCon.UPDATE_OBJECT_VALUE:
            state = { ...state, [action.payload[libCon.ID]]: { ...state[action.payload[libCon.ID]], ...action.payload[libCon.VALUE] } }
            break

        case locCon.RESET_ALL_DATASETS:
            state = {
                ...state,
                // Status
                [libCon.STATUS_DATASET_PARTICIPANTS]: libCon.NOT_STARTED,
                [libCon.STATUS_DATASET_SENSORS]: libCon.NOT_STARTED,
                [libCon.STATUS_DATASET_HOUSES]: libCon.NOT_STARTED,
                [libCon.STATUS_DATASET_PARTICIPANT_PLACEMENT]: libCon.NOT_STARTED,
                [libCon.STATUS_DATASET_SENSOR_HOUSE_PLACEMENT]: libCon.NOT_STARTED,
                [libCon.STATUS_DATASET_RECEIVED_SENSOR_FILES]: libCon.NOT_STARTED,


                // Dataset
                [libCon.DATASET_PARTICIPANTS]: null,
                [libCon.DATASET_SENSORS]: null,
                [libCon.DATASET_HOUSES]: null,
                [libCon.DATASET_PARTICIPANT_PLACEMENT]: null,
                [libCon.DATASET_SENSOR_HOUSE_PLACEMENT]: null,
                [libCon.DATASET_RECEIVED_SENSOR_FILES]: null
            }
            break

        default:
            break

    }

    // Final manipulations
    // Cleanup scripts

    return ({ ...state })


}

