import * as libCon from "../Constants"
import axios from "axios";

export const buildFitbitURL = (clientID) => {
  return (`https://www.fitbit.com/oauth2/authorize?response_type=token&client_id=${clientID}&expires_in=31536000&redirect_uri=https://dev-redirect.community-hats.net&scope=activity%20heartrate%20sleep%20temperature%20settings%20profile`)
}

export const testFitbitApi = async (requestId, token) => {


  const link = `https://api.fitbit.com/1/user/${requestId}/activities/heart/date/2024-01-01/1d/1min.json`;


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 200)
      return libCon.OK


  } catch (error) {

    if (error.message === 'Network Error')
      return libCon.NETWORK_ERROR

    return libCon.ERROR

  }

}


export const getLastSync = async (requestId, token) => {


  const link = `https://api.fitbit.com/1/user/${requestId}/devices.json`;


  try {
    const response = await axios.get(link, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });


    if (response.status === 200) {
      let data = response.data

      if (data.length > 0 && "lastSyncTime" in data[0]) {

        const lastSynched = data[0]["lastSyncTime"];

        if (isNaN(new Date(lastSynched)))
          return [libCon.OK, null]

        return [libCon.OK, lastSynched]

      }
      else
        return [libCon.NOT_FOUND, null]

    }

  } catch (error) {

    if (error.message === 'Network request failed')
      return ([libCon.NETWORK_ERROR, null])

    return ([libCon.ERROR, null])

  }
}