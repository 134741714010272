import React, { useEffect, useReducer } from 'react'
import { Button, Progress, Spin } from 'antd';
import * as libCon from "../community-hats-js-library/Constants"
import * as locCon from "../LocalConstants"

import { BarChart, Bar, XAxis, YAxis, Tooltip, Rectangle } from 'recharts';
import { add, format, differenceInCalendarDays, addDays } from "date-fns";
import { RefParticipantsDeploymentData, refreshAllDatasets } from '../hooks/DatasetHooks';
import Title from 'antd/es/typography/Title';
import { sum } from 'd3';



const dateFormatter = date => {
  return format(new Date(date), "dd/MMM");
};

const getTicks = (startDate, endDate, num) => {
  const diffDays = differenceInCalendarDays(endDate, startDate);

  let current = startDate,
    velocity = Math.round(diffDays / (num - 1));

  const ticks = [startDate.getTime()];

  for (let i = 1; i < num - 1; i++) {
    ticks.push(add(current, { days: i * velocity }).getTime());
  }

  ticks.push(endDate.getTime());
  return ticks;
};


// Reducer constants
const CHART_DATA = "chart_data"
const DOMAIN = "domain"
const TICKS = "ticks"
const TOTAL = "total"

// Actions
const UPDATE_DATA = "UPDATE_DATA"

const initialState = {
  [CHART_DATA]: null,
  [DOMAIN]: null,
  [TICKS]: null,
  [TOTAL]: "--"
}





function DashboardDeploymentProgress() {


  // Participants
  const [status, participantsByDate] = RefParticipantsDeploymentData()


  const reducer = (state, action) => {

    switch (action.type) {
      case UPDATE_DATA:

        let dates = participantsByDate.map(ob => ob[libCon.DATE])
        const start_date = new Date(Math.min(...dates)).getTime()
        const end_date = addDays(new Date(), 1).getTime();

        state = {
          ...state,
          [CHART_DATA]: participantsByDate,
          [DOMAIN]: [start_date, end_date],
          [TICKS]: getTicks(new Date(start_date), new Date(end_date), 8),
          [TOTAL]: sum(participantsByDate.map(ob => ob[locCon.PARTICIPANTS]))

        }
        return state
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);


  useEffect(() => {

    if (participantsByDate !== null && participantsByDate !== undefined)
      dispatch({ type: UPDATE_DATA });

  }, [participantsByDate])

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${format(label, 'yyyy-MM-dd')}`}</p>
          <p className="number">{`Participants Deployed: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };




  return (
    <div className="mainContainer">
      <Title level={1}>{`Deployment Progress`}</Title>
      <Button type="primary" onClick={() => refreshAllDatasets()}>Refresh</Button>
      <Title level={3}>{`Total Deployed Participants: ${state[TOTAL]} of ${locCon.TARGET_PARTICIPANTS}`}</Title>

      {
        status === libCon.NOT_STARTED || status === libCon.LOADING || state[CHART_DATA] === null
          ? <div>
            <Spin />
          </div>
          : status === libCon.ERROR
            ? <div>Error</div>
            : <>
              <div style={{ width: "80%", marginBottom: "5%" }} >
                <Progress percent={Math.round(100 * (state[TOTAL] / locCon.TARGET_PARTICIPANTS))} strokeColor="#8884d8" style={{ fontSize: "30px", color: "white" }} />
              </div>
              <Title level={4}>{`Participants Deployed by Day`}</Title>
              <BarChart width={1200} height={300} data={state[CHART_DATA]}>

                <XAxis dataKey={libCon.DATE} tickFormatter={dateFormatter} domain={state[DOMAIN]} ticks={state[TICKS]} />
                <YAxis />
                <Bar dataKey={locCon.PARTICIPANTS} fill="#8884d8" activeBar={<Rectangle fill="red" stroke="white" />} />
                <Tooltip content={<CustomTooltip />} />
              </BarChart>
            </>

      }

    </div>


  )
}

export default DashboardDeploymentProgress