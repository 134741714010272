import { useSelector } from "react-redux";
import * as locCon from "../LocalConstants"
import { setValue } from "../store/actions/generalStoreActions";

/**
 * Hook for a single user attriburte in the store read only
 */
export function RefStoreValue(id) {

    const value = useSelector(state => state[locCon.STORE][id])

    return (value);
}

/**
 * Hook for a single user attriburte in the store read only
 */
export function StateStoreValue(id) {

    const value = useSelector(state => state[locCon.STORE][id])
    const setVal = (val) => setValue(id, val)

    return ([value, setVal]);
}



// Function for filtering Objects
export const filterObject = (obj, predicate) => {

    return (Object.keys(obj)
        .filter(key => predicate(obj[key]))
        .reduce((res, key) => { return Object.assign(res, { [key]: obj[key] }) }, {}))


}